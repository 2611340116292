<template>

  <div>
    <v-flex xs12 md12 sm12>
      <v-carousel hide-delimiters cycle height="600">
  <v-carousel-item

    v-for="(item,i) in items2"
    :key="i"

    :src="item.src"


  >
  <v-row
   class="fill-height"
   align="center"
   justify="center"
 >
   <div class="text-h2" style="color:white;">
     <h2  class="titulo2" style=" font-family: 'Satisfy', cursive; font-size: 66px;">finca hotel la chagra</h2>
   </div>
 </v-row></v-carousel-item>
</v-carousel>
      <!-- <div class="hero-image">
      <div class="hero-text">
        <h2 class="tituloimg"
         style="font-size: 60px; font-family: ui-monospace, background-color: rgba(0,0,0,0.64)!important;">Finca hotel la chagra1</h2>
      </div>
      </div> -->
    </v-flex>

    <v-container grid-list-md>
    <v-layout row wrap>

     <v-flex xs12 md12 sm12>
    <br><br><br>
    <div class="text-center" >
    <h1 style="font-size: 40px; font-family: ui-monospace;">RESERVACIONES LA CHAGRA</h1>
   </div>
    <div style=" justify-content: center; display: flex;">
     <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
    </div>
    <br><br>
  </v-flex>


  <v-flex xs12 md6 sm6>
  <v-container>

    <h1 style="font-size: 30px; font-family: ui-monospace;">UBICACIÓN </h1>
    <p>Ubicado a 20 min del Aeropuerto Viniendo de Armenia - llegan a Pueblo Tapao, a la estación de policía y sigue derecho hacia once casas pasados 3 min encuentra la finca hotel la chagra al lado derecho. ubicaciòn. </p>
    <h2 style="font-size: 30px; font-family: ui-monospace;">NUMEROS DE CONTACTO</h2>
    <p>3117433000 - 3117671144</p>
    <h2 style="font-size: 30px; font-family: ui-monospace;">CORREO:</h2>
    <p>agenciamagicgreen@gmail.com</p>
  </v-container>
  </v-flex>

  <v-flex xs12 md6 sm6>

    <v-card>


    <v-toolbar color="cyan" dark style="justify-content: center; display: flex;"  >
     <v-toolbar-title>PIDE TU RESERVA</v-toolbar-title>
     <v-spacer></v-spacer>
   </v-toolbar>
   <v-container>
    <v-form>

    <v-row>

    <v-col cols="12" sm="12" md="12" >
    <v-text-field label="Nombre" v-model="nombre"></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="12" >
      <v-dialog
             ref="dialog"
             v-model="modal"
             :return-value.sync="date"
             persistent
             width="290px"
           >
             <template v-slot:activator="{ on, attrs }">
               <v-text-field
                 v-model="date"
                 label="Fecha de reserva"
                 prepend-icon="mdi-calendar"
                 readonly

                 v-bind="attrs"
                 v-on="on"
               ></v-text-field>
             </template>
             <v-date-picker
             range
               v-model="date"
                locale="co"
               scrollable
             >
               <v-spacer></v-spacer>
               <v-btn
                 text
                 color="primary"
                 @click="modal = false"
               >
                 Cancel
               </v-btn>
               <v-btn
                 text
                 color="primary"
                 @click="$refs.dialog.save(date)"
               >
                 OK
               </v-btn>
             </v-date-picker>
           </v-dialog>


    <!-- <v-text-field label="Telefono" v-model="telefono"></v-text-field> -->
    </v-col>

    <v-col cols="12" sm="12" md="12">
    <v-text-field label="Cantidad de personas" v-model="personas"></v-text-field>
    </v-col>
    </v-row>

  </v-form>
  </v-container>

  <v-col cols="12" sm="12" md="12" style="justify-content: center; display: flex;" >
  <v-btn color="error"  @click="enviar()"> agendar </v-btn>
  </v-col>
  </v-card>
  </v-flex>



 <v-card>


   <a href="https://wa.me/573117671144?text=Me%20gustaría%20saber%20elos%20precios" class="whatsapp" target="_blank"> <i class="fab fa-whatsapp" style="color: white;margin-top: 14px;"></i></a>
 </v-card>



  <v-flex xs12 md12 sm12>
    <br><br>
    <div class="text-center" >
    <h2 style="font-size: 40px; font-family: ui-monospace;">NUESTROS PLANES</h2>
   </div>
    <div style=" justify-content: center; display: flex;">
     <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
    </div>
    <br><br>
  </v-flex>



  <v-flex xs12 md12 sm12>

       <div class="columns">
         <ul class="price">
           <li class="header">Pasadia</li>
           <li class="grey">$ 16.000</li>
           <li>Piscina</li>
           <li>Zonas verdes</li>
           <li>Canchas</li>
           <li><br></li>
         </ul>
       </div>

       <div class="columns">
         <ul class="price">
           <li class="header" style="background-color:#04AA6D">Pasadia con almuerzo</li>
           <li class="grey">$ 33.000</li>
           <li>Almuerzo</li>
           <li>piscina</li>
           <li>zonas verdes</li>
           <li>Canchas</li>

         </ul>
       </div>

       <div class="columns">
         <ul class="price">
           <li class="header">Cena romantica</li>
           <li class="grey">$ 300.000</li>
           <li>Hospedaje</li>
           <li>cena especial, botella de vino</li>
           <li>Desayuno</li>
           <li>Decoración</li>

         </ul>
       </div>




  </v-flex>
   <v-flex xs12 md12 sm12>
    <br><br>
    <div class="text-center" >
    <h2 style="font-size: 40px; font-family: ui-monospace;">FOTOS DE NUESTRAS  HABITACIONES</h2>
   </div>
    <div style=" justify-content: center; display: flex;">
     <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
    </div>
    <br><br>

   




   <b-carousel
        :autoplay="false"
        with-carousel-list
        :indicator="false"
        :overlay="gallery2"
        >

        <b-carousel-item v-for="(item, i) in items3" :key="i">
            <figure class="image">
                <img :src="item.image" :alt="item.des">
            </figure>
        </b-carousel-item>
        <span v-if="gallery2" @click="switchGallery(false)" class="modal-close is-large"/>
        <template #list="props">
            <b-carousel-list
                v-model="props.active"
                :data="items3"
                v-bind="al"
                @switch="props.switch($event, false)"
                as-indicator />
        </template>
        <template #overlay>
            <div class="has-text-centered has-text-white">
                Finca hotel la chagra
            </div>
        </template>
    </b-carousel>

  </v-flex>




    <v-flex xs12 md12 sm12>


      <br><br><br><br><br><br>
      <div class="text-center" >
      <h2 style="font-size: 40px; font-family: ui-monospace;">FOTOS DE NUESTRAS INSTALACIONES</h2>
      </div>
      <div style=" justify-content: center; display: flex;">
       <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
      </div>




    <b-carousel
     :autoplay="false"
     with-carousel-list
     :indicator="false"
     :overlay="gallery"
     @click="switchGallery(true)">

 <b-carousel-item v-for="(item, i) in items" :key="i">
     <figure class="image">
         <img :src="item.image">
     </figure>
 </b-carousel-item>

   <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>

       <template #list="props">
           <b-carousel-list
               class="image2"
               v-model="props.active"
               :data="items"
               v-bind="al"
               @switch="props.switch($event, false)"
               as-indicator />
       </template>

    <template #overlay>
    <div class="has-text-centered has-text-white">
       finca hotel la chagra
    </div>
  </template>

  </b-carousel>
  </v-flex>

        <v-flex xs12 md12 sm12>
          <br><br>
          <div class="text-center" >
          <h2 style="font-size: 40px; font-family: ui-monospace;">SERVICIOS</h2>
         </div>
          <div style=" justify-content: center; display: flex;">
           <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
          </div>
          <br><br>
        </v-flex>


        <v-flex xs12 md12 sm12>

             <div class="columns">
               <ul class="price">
                 <li class="header">RESTAURANTE</li>
                 <li style="background-color:#ffffff;"><br></li>

               </ul>
             </div>


             <div class="columns">
               <ul class="price">
                 <li class="header" style="background-color:#04AA6D">BAR</li>
                 <li style="background-color:#ffffff;"><br></li>

               </ul>
             </div>



             <div class="columns">
               <ul class="price">
                 <li class="header">Piscina</li>
                 <li style="background-color:#ffffff;"><br></li>

               </ul>
             </div>
             </v-flex>
             <v-flex xs12 md12 sm12>




             <div class="columns">
               <ul class="price">
                 <li  class="header" style="background-color:#04AA6D">zonas verdes</li>
                 <li style="background-color:#ffffff;"><br></li>
               </ul>
             </div>



             <div class="columns">
               <ul class="price">
                 <li class="header">Canchas</li>
                 <li style="background-color:#ffffff;"><br></li>
               </ul>
             </div>



             <div class="columns">
               <ul class="price">
                 <li  class="header" style="background-color:#04AA6D">Parqueadero</li>
                 <li style="background-color:#ffffff;"><br></li>
               </ul>
             </div>







        </v-flex>


 

 <v-flex xs12 md12 sm12>
   <br><br><br>
   <div class="text-center" >
   <h2 style="font-size: 40px; font-family: ui-monospace;">UBICACIÓN</h2>
  </div>
   <div style=" justify-content: center; display: flex;">
    <hr style="width: 70%!important; border-top: 1px solid red;"></hr>
   </div>
   <br><br>
 </v-flex>


 <v-flex xs12 md6 sm12>
   <img style="height: 240px;
    width: 70%;
    border-radius: 50px;" src="https://res.cloudinary.com/webproyectos/image/upload/v1601834690/1_gg5jco.jpg"></img>


    <img style="height: 240px;
    width: 70%;
    border-radius: 50px;" src="https://res.cloudinary.com/webproyectos/image/upload/v1601834690/2_p6qdht.jpg"></img>
 </v-flex>

  <v-flex xs12 md6 sm12>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2988.2636359330554!2d-75.8117426852383!3d4.513307996720658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e385ebaca9d3d47%3A0x110e939221ec94b2!2sFinca%20Hotel%20La%20Chagra!5e1!3m2!1ses!2sco!4v1602617643233!5m2!1ses!2sco" width="100%" height="500" frameborder="0" allowfullscreen="allowfullscreen" aria-hidden="false" tabindex="0" style="border: 0px;"></iframe>
  </v-flex>

</v-layout>
  </v-container>
</div>
</template>


<script>

  export default {
    data() {
      return {
        nombre: "",
        personas: "",
        fecha: "",
        url: "https://wa.me/573117671144?text=",
        modal: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        gallery: false,
        gallery2: false,
        al: {
          hasGrayscale: true,
          itemsToShow: 2,
          breakpoints: {
            768: {
              hasGrayscale: false,
              itemsToShow: 4
            },
            960: {
              hasGrayscale: true,
              itemsToShow: 6
            }
          }
        },
        items3: [
           {
            title: 'Slide 1',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.21%20AM%20(1).jpeg?alt=media&token=b4a4da0c-468c-4077-b203-65a62af98f89',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 2',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.21%20AM%20(2).jpeg?alt=media&token=c188b69f-a57b-461e-b3e8-74f6dd033a6d',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 3',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.21%20AM.jpeg?alt=media&token=d4ed9573-bcce-4e48-b00d-9724a267d68d',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 4',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.22%20AM%20(1).jpeg?alt=media&token=4ba07c26-9070-43dd-8b57-741892336c30',
            des:'habitaiones chagra'
          },
             {
            title: 'Slide 5',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.22%20AM%20(2).jpeg?alt=media&token=d3ed9218-9495-41f8-976a-48dfc0475e64',
            des:'habitacion doble chagra'
          },
             {
            title: 'Slide 6',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.22%20AM%20(3).jpeg?alt=media&token=aabb0bce-8d7f-4778-8758-6bef67eae74c',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 7',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.22%20AM%20(4).jpeg?alt=media&token=c0f508cb-b793-45b5-803e-0a90eccfbbd7',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 8',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.22%20AM.jpeg?alt=media&token=3ca92360-14c1-46be-9a3b-550846a0d7c6',
            des:'fotos finca hotel la chagra'
          },
             {
            title: 'Slide 9',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/habitaciones%2FWhatsApp%20Image%202021-10-26%20at%202.40.23%20AM.jpeg?alt=media&token=62eafdb4-7054-41f0-9c7d-0a9250e3ac37',
            des:'fotos finca hotel la chagra'
          }

        ],
        items2: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/IMG_20190901_175810558_HDR.jpg?alt=media&token=6f7f4ea1-fddb-4fdf-a295-72630bb78a9c',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/3c143ed0-c426-43ff-86b5-ceb57ce50093.jpg?alt=media&token=867d75eb-170c-4f6e-ba43-9de6750ca036',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/6654db27-edd9-4651-b5c1-a6d9143b55c5.jpg?alt=media&token=b5ec834b-1c22-4733-bfc3-2747a1e73f7b',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/3e78e244-3a75-4ea0-9027-7b8325373de7.jpg?alt=media&token=a15db41f-2354-4f7d-9e85-d5b5af853daa',
        },
      ],
        // servicios: [
        //   {
        //     src:
        //       'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/nadador.png?alt=media&token=d1cc7025-7267-4883-b3ae-ea7b1feec961'
        //   },
        //   {
        //     src:
        //       'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/parque%20(1).png?alt=media&token=eb41bd45-dfe9-4169-a287-d0d86774f16c'
        //   },
        //   {
        //     src:
        //       'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/plato.png?alt=media&token=b2a0d60b-5d40-4391-9a1f-79a497bc1942'
        //   }
        // ],
        items: [
          // {
          //   title: 'Slide 1',
          //   image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/IMG_20190901_175810558_HDR.jpg?alt=media&token=6f7f4ea1-fddb-4fdf-a295-72630bb78a9c0'
          // },
          // {
          //   title: 'Slide 2',
          //   image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/IMG_20210725_110200447_HDR.jpg?alt=media&token=1300be43-b8c9-486b-9246-08bdd464536a'
          // },
          {
            title: 'Slide 3',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/IMG_20190901_175810558_HDR.jpg?alt=media&token=6f7f4ea1-fddb-4fdf-a295-72630bb78a9c'
          },
          {
            title: 'Slide 4',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/2b17e1c6-8612-4ab6-b557-badcc7289603.jpg?alt=media&token=531106bd-c65f-43d9-94b3-fee0273e86f4'
          },
          {
            title: 'Slide 5',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/36b63ba0-9bd3-424f-ae86-93f76467f7de.jpg?alt=media&token=c9a31995-8adb-417b-8382-cd60397e9f43'
          },
          {
            title: 'Slide 6',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/3c143ed0-c426-43ff-86b5-ceb57ce50093.jpg?alt=media&token=867d75eb-170c-4f6e-ba43-9de6750ca036'
          },
          {
            title: 'Slide 7',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/6654db27-edd9-4651-b5c1-a6d9143b55c5.jpg?alt=media&token=b5ec834b-1c22-4733-bfc3-2747a1e73f7b'
          },
          {
            title: 'Slide 8',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/688ae175-c51d-4317-b34f-dbf9e7a8f3be.jpg?alt=media&token=6e518192-faeb-4e3b-9ec0-1329960a466c'
          },
          {
            title: 'Slide 9',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/6a3c0cf9-0fa9-4974-af8e-b43f002b45f4.jpg?alt=media&token=5a02c4e8-5c70-4642-9bb9-ea492b0037d6'
          },
          {
            title: 'Slide 10',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/7c23681f-5180-4901-9148-02aa41ee2773.jpg?alt=media&token=610458a3-eb59-4f6c-a91c-ee15c30a5049'
          },
          {
            title: 'Slide 11',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/85a6510f-c6f0-4982-8ffe-0ade1e69a99c.jpg?alt=media&token=4b8747c5-fdf2-467f-9cb2-5faeccfcf0f2'
          },
          {
            title: 'Slide 12',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/8bb9666e-5621-40b4-a411-e85ba126f0aa.jpg?alt=media&token=702f1b7f-7373-4504-81e4-cdba1b232b0c'
          },
          {
            title: 'Slide 13',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/98992ece-8902-42bd-8367-578f2a2317bb.jpg?alt=media&token=ae16bb31-0f79-448d-83ba-e00ec15e747d'
          },
          {
            title: 'Slide 14',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/9b3bdf3a-d8fa-435e-ae18-cc941e3f061b.jpg?alt=media&token=795b937c-f5ef-4737-a504-b05c30db9b5f'
          },
          {
            title: 'Slide 15',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/9cee2b60-92b1-4024-b6cc-d58a13e1ed87.jpg?alt=media&token=2b5a2a70-1231-411e-add6-71e820521434'
          },
          {
            title: 'Slide 16',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/c6a6f405-4772-4651-bfc2-1a7adf24c9a6.jpg?alt=media&token=d10baea2-5bbf-4d29-a1cb-6d565bbdb86b'
          },
          {
            title: 'Slide 17',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/d3d091ba-d593-4c47-9d47-bf56e3f8976b.jpg?alt=media&token=d1fabac6-59e0-47a6-8159-62d601dccbe6'
          },
          {
            title: 'Slide 18',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/d78f03db-41fc-423b-9d7e-02b7822c8603.jpg?alt=media&token=574b029b-06a2-4d2d-9d42-28397e66acee'
          },
          {
            title: 'Slide 19',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/dd5dbb31-eaa4-4c9f-bd16-871cb7559f0a.jpg?alt=media&token=b551517d-e26e-40c6-a97d-61bf1de8b91b'
          },
          {
            title: 'Slide 20',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/e4155e7f-6a72-47bb-b887-9c7d6bf5d7e6.jpg?alt=media&token=30ea2cae-c3a3-428b-8170-a6ac75ae584b'
          },
          {
            title: 'Slide 21',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/e9f48479-156f-4cd3-8a30-e6971141bf0b.jpg?alt=media&token=857245b7-8c9f-4afc-b2ca-62eb38b52507'
          },
          {
            title: 'Slide 22',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/ef087e10-4648-4d15-b4b7-52bc1dbc5c5f.jpg?alt=media&token=2d2d2224-058f-4716-a821-6d92707cc725'
          },
          {
            title: 'Slide 23',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/f55fbbd6-d0f7-4393-a173-846ab711e151.jpg?alt=media&token=1468b16e-7099-4d29-9ddc-c82b8a96fbd4'
          },
          {
            title: 'Slide 24',
            image: 'https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/f6d678bb-5c96-4e4a-a677-6ed3d792281e.jpg?alt=media&token=308c8f44-81d8-4d30-afcd-1cf0dc6b953a'
          }
        ]
      }
    },
    methods: {
      enviar() {
         window.open(`${this.url}`+`Quiero agendar una reserva para la finca hotel la chagra y me gustaria saber los planes y precios que menjan. `+` mi nombre es : ${this.nombre}`+` fecha de reserva : ${this.date}`+` cantidad de personas : ${this.personas}`,"_blank");

      },
      switchGallery(value) {
        this.gallery = value
        if (value) {
          document.documentElement.classList.add('is-clipped')
        } else {
          document.documentElement.classList.remove('is-clipped')
        }
      },
           switchGallery2(value) {
            this.gallery2 = value
            if (value) {
                document.documentElement.classList.add('is-clipped')
            } else {
                document.documentElement.classList.remove('is-clipped')
            }
        }
    }
  }

</script>
<style>

  /* --------------css de la portada-------------------- */
  body,
  html {
    height: 100%;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  .hero-image {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/fincahotellachagra-567dc.appspot.com/o/IMG_20190901_175810558_HDR.jpg?alt=media&token=6f7f4ea1-fddb-4fdf-a295-72630bb78a9c');
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  /* --------fin------------------ */

   .carousel-slide{
     height: 200px!important;
     }
   /* .image2{
    height: 240px!important;

     } */


        /* TABLA DE PRECIOS------------------------- */
.columns {
  float: left;
  width: 35.3%;
  /* padding: 8px; */
  display: flex;
  justify-content: center;
}

.price {
  list-style-type: none;
  /* border: 1px solid #eee; */
  width: 89%;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  /* box-shadow: 0 5px 12px 0 rgba(0,0,0,0.2) */
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}

.button {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
    display: flex;
    justify-content: center;

  }
}
@media only screen and (max-width: 800px) {
  .price {
    list-style-type: none;
    /* border: 1px solid #eee; */
    width: 80%!important;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

}
/* ------FIN  */

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
.whatsapp {
  position: fixed;
  width: 60px;
  cursor: pointer;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 13px;
}
li{
  border-bottom:1px solid #7e7e7e!important;
}

@media only screen and (max-width: 200px) {
  .h2 {
    font-size: 40px!important;


  }
}

@media only screen and (max-width: 600px) {
  .h2 {
    font-size: 45px!important;


  }
}
@media only screen and (max-width: 800px) {
  .h2 {
    font-size: 45px!important;

  }

}


.titulo2 {
    border: 1px solid rgb(0,0,0)!important;
    border-radius: 5px!important;
    background-color: rgba(0,0,0,0.64)!important;
    padding: 17px!important;
}
.white .v-toolbar__content {
    background-color: rgb(0,0,0);
}
.tituloimg {
    font-family: inherit;
    font-size: 21px;
    color: white!important;
}
.v-btn__content .mr-2 {
    color: white!important;
}

.header {
    background-color: #00bcd4!important;
}
.v-application .grey {
    background-color: white!important;
}
 li {
    background-color: white!important;
}

</style>
