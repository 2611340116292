<template>
  <v-app style="background-color: aliceblue;">
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/vue-upload-c1a45.appspot.com/o/cabeza-de-caballo-en-herradura.png?alt=media"
          alt="imagen la chagra titulo"
          transition="scale-transition"
          width="50"
        />

       <h1 style="color:gray;">Finca Hotel la chagra</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        
        target="_blank"
        text
      >
        <span class="mr-2" style="color:gray;">3117433000 - 3117671144</span>
 
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
