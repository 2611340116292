import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(Buefy);

Vue.use(Vuetify);

export default new Vuetify({
});
